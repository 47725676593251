import { UserEntity } from "@foudroyer/interfaces"

declare global {
  interface Window {
    $crisp: Array<any>
    CRISP_WEBSITE_ID: string
  }
}

export const startCrisp = () => {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = "b5235ee2-e9d5-4d06-9c92-488f64e57c8d"

  const script = document.createElement("script")
  script.src = "https://client.crisp.chat/l.js"
  script.async = true

  document.body.appendChild(script)
}

export const getCrispUrl = (user?: UserEntity | null) => {
  if (!user)
    return `https://go.crisp.chat/chat/embed/?website_id=b5235ee2-e9d5-4d06-9c92-488f64e57c8d`
  return `https://go.crisp.chat/chat/embed/?website_id=b5235ee2-e9d5-4d06-9c92-488f64e57c8d&user_email=${user.email}&token_id=${user.id}&session_merge=true&crisp_sid=${user.id}&user_nickname=${user.id}`
}
